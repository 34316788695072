import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import { DataGrid } from "@mui/x-data-grid";
import DeleteUser from "./deleteUser";
import ResetUserPwd from "./resetPwd";

import { getUserMgmtDetails, activateUser } from "../redusers/userThunk";

const UserManagement = (props) => {
  const dispatch = useDispatch();
  const [usersLst, setUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState(false);
  const [usersData, setUsersData] = useState();
  const [resetPwd, setResetPwd] = useState();
  const [SelectedUser, setSelectedUser] = useState([]);
  const [deleteSelectedUser, setDelete] = React.useState(false);

  useEffect(() => {
    dispatch(getUserMgmtDetails())
      .unwrap()
      .then((resdata) => {
        setUsers(resdata?.response.users);
      });
  }, []);

  const changePasswordData = (params) => {
    setResetPwd(params.row);
    setChangePassword(true);
  };
  const handleClose1 = () => {
    setChangePassword(false);
  };

  const deleteUserData = (params) => {
    setSelectedUser(params.row);
    setDelete(true);
  };

  const activateUserData = (params) => {
    dispatch(activateUser(params.row.user_id))
      .unwrap()
      .then(() => {
        toast.success("User Activated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        dispatch(getUserMgmtDetails())
          .unwrap()
          .then((resdata) => {
            setUsers(resdata?.response.users);
          });
      });
  };

  const handleClickOpen = (params) => {
    const data = usersLst.filter((d) => d.user_id === params.row.user_id);
    setUsersData(data[0]);
    // dispatch(setReferalDoctorFormData(data[0]));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const columns = [
    {
      field: "user_name",
      headerName: "Username",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.user_name}</span>
      ),
    },
    {
      field: "age",
      headerName: "User Type",
      width: 110,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.user_type}</span>
      ),
    },
    {
      field: "hospital_name",
      headerName: "Org Name",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.hospital_name}</span>
      ),
    },
    {
      field: "action",
      headerName: "Last Log In time",
      width: 80,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">
          {moment(params.row.createdAt).format("DD - MM - YY")}
          <div
            className="dadata"
            style={{ textAlign: "center", color: "#6A6A6A", fontWeight: 400 }}
          >
            {moment(params.row.createdAt).format("hh:mm:ss")}
          </div>
        </span>
      ),
    },
    {
      field: "fullName",
      headerName: "Action Buttons",
      width: 80,
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: 10,
              background: "#6572E4",
              //background: "#F98F60",
              borderRadius: 4,
              fontSize: 10,
              boxShadow: "none",
              textTransform: "none",
            }}
            className="view_Reports"
            onClick={() => handleClickOpen(params)}
          >
            View User
          </Button>
          {params.row.active_flag ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#FF6347",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => deleteUserData(params)}
            >
              Delete
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                //background: "#F98F60",

                background: "#008000",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => activateUserData(params)}
            >
              Activate
            </Button>
          )}
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: 10,
              background: "#008000",
              //background: "#F98F60",
              borderRadius: 4,
              fontSize: 10,
              boxShadow: "none",
              textTransform: "none",
            }}
            className="view_Reports"
            onClick={() => changePasswordData(params)}
          >
            Reset
          </Button>
        </strong>
      ),
    },
  ];

  const closeDialog = () => {
    setDelete(false);
  };
  const closeDialog1 = () => {
    setChangePassword(false);
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="dataoutlet2">
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Card variant="none">
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <p className="dashboard-title">User Management Listing</p>
                  </Grid>
                </Grid>
                <Box sx={{ height: 600, width: "100%" }}>
                  <DataGrid
                    rows={usersLst}
                    columns={columns}
                    showCellRightBorder={true}
                    showColumnRightBorder={true}
                    getRowId={(row) => row.user_id}
                    pageSize={10}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "0.8px solid",
              borderColor: "rgba(101, 114, 228, 0.4)",
            }}
          >
            <span>
              <Link
                className="FIle-left"
                onClick={handleClose}
                style={{ marginRight: "20px" }}
              >
                <img
                  src={Back}
                  className="file-img-left"
                  alt=""
                  style={{ width: "12px" }}
                />
              </Link>
              View User
            </span>
            <Link className="FIle-right-extreme" onClick={handleClose}>
              <img src={Close} style={{ marginRight: "20px" }} alt="" />
            </Link>
          </DialogTitle>
          <DialogContent>
            <form id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="User Name"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    User Name
                  </label>
                  <TextField
                    id="user_name"
                    name="user_name"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    value={usersData?.user_name}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="Email ID"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Email Id
                  </label>
                  <TextField
                    id="email_address"
                    name="email_address"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    type="email"
                    value={usersData?.email_address}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="Phone Number"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Phone Number
                  </label>
                  <TextField
                    id="contact_number"
                    name="contact_number"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    value={usersData?.contact_number}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="Institution Name"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Institution Name
                  </label>
                  <TextField
                    id="hospital_name"
                    name="hospital_name"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    value={usersData?.hospital_name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="Institution Alias Name"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Institution Alias Name
                  </label>
                  <TextField
                    id="institution_alias_name"
                    name="institution_alias_name"
                    label=""
                    variant="outlined"
                    className="dataentry_input_add"
                    size="small"
                    value={usersData?.institution_alias_name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="State"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    State
                  </label>
                  <TextField
                    id="state"
                    name="state"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    value={usersData?.state}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="City"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    City
                  </label>
                  <TextField
                    id="city"
                    name="city"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    value={usersData?.city}
                  />
                </Grid>
              </Grid>

              <div className="formSection">
                <Grid container style={{ justifyContent: "center" }}>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      className="back"
                      onClick={handleClose}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
          </DialogContent>
        </Dialog>

        {/*  <Dialog open={pwd} style={{ maxHeight: "100%" }}>
          <DialogTitle
            style={{
              borderBottom: "0.8px solid",
              borderColor: "rgba(101, 114, 228, 0.4)",
            }}
          >
            <Link
              className="FIle-left"
              onClick={handleClose1}
              style={{ marginRight: "20px" }}
            >
              <img
                src={Back}
                className="file-img-left"
                alt=""
                style={{ width: "12px" }}
              />
            </Link>
            Change Password
            <Link className="FIle-right-extreme" onClick={handleClose1}>
              <img src={Close} alt="" style={{ paddingLeft: "200px" }} />
            </Link>
          </DialogTitle>
          <DialogContent>
            <form id="bmrn-form">
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <label
                    htmlFor="Old password"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Old Password
                  </label>
                  <TextField
                    id="oldPassword"
                    name="oldPassword"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    type={"password"}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <label
                    htmlFor="New Password"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    New Password
                  </label>
                  <TextField
                    id="newPassword"
                    name="newPassword"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    type={"password"}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <label
                    htmlFor="Confirm New Password"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Confirm New Password
                  </label>
                  <TextField
                    id="confirm_password"
                    name="confirm_password"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    type={"password"}
                    //onChange={FormChange}
                  />
                </Grid>
              </Grid>
              <div className="formSection">
                <Grid container>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      className="purple-add"
                      type="submit"
                    >
                      <span>Save</span>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
          </DialogContent>
        </Dialog> */}
      </div>
      {deleteSelectedUser && (
        <DeleteUser
          SelectedUser={SelectedUser}
          open={deleteSelectedUser}
          onClose={closeDialog}
        />
      )}
      {changePassword && (
        <ResetUserPwd
          resetPwd={resetPwd}
          open={changePassword}
          onClose={closeDialog1}
        />
      )}
    </React.Fragment>
  );
};
export default UserManagement;
