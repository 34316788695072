import React from "react";
import Home from "../common/components/Home";
import { ALL } from "../common/utils/consts";
import LoginScreen from "../login/components/loginScreen";
import Dashboard from "../Dashboard/components/Dashboard";
import LoginOTP from "../login/components/LoginOTP";
import UserManagement from "../UserManagement/components/userManagement";
import OrgManagement from "../orgManagement/components/orgManagement";
import LogManagement from "../logManagement/components/logManagement";
import WorkManagement from "../workManagement/components/workMgmt";
import Coupons from "../Coupons/components/Coupon";
import Plans from "../plans/components/payment_plans";
import RequireAuth from "./RequireAuth";
import Reports from "../Reports/components/Reports";
import ForgotPassword from "../login/components/forgetPwd";
import NewPassword from "../login/components/newpassword";
export const appRoutes = [
  {
    path: "/",
    element: <Home />,
    children: [
      {
        index: true,
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <Dashboard />
          </RequireAuth>
        ),
      },

      {
        path: "/Dashboard",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <Dashboard />
          </RequireAuth>
        ),
      },

      {
        path: "/userManagement",
        element: (
          <RequireAuth roles={[ALL]}>
            <UserManagement />
          </RequireAuth>
        ),
      },

      {
        path: "/orgManagement",
        element: (
          <RequireAuth roles={[ALL]}>
            <OrgManagement />
          </RequireAuth>
        ),
      },

      {
        path: "/logManagement",
        element: (
          <RequireAuth roles={[ALL]}>
            <LogManagement />
          </RequireAuth>
        ),
      },

      {
        path: "/workManagement",
        element: (
          <RequireAuth roles={[ALL]}>
            <WorkManagement />
          </RequireAuth>
        ),
      },

      {
        path: "/Coupons",
        element: (
          <RequireAuth roles={[ALL]}>
            <Coupons />
          </RequireAuth>
        ),
      },

      {
        path: "/Plans",
        element: (
          <RequireAuth roles={[ALL]}>
            <Plans />
          </RequireAuth>
        ),
      },

      {
        path: "/Reports",
        element: (
          <RequireAuth roles={[ALL]}>
            <Reports />
          </RequireAuth>
        ),
      },
    ],
  },

  // {
  //   path: "/Dashboard",
  //   element: <Dashboard />,
  // },
  {
    path: "/LoginOTP",
    element: <LoginOTP />,
  },
  {
    path: "/Home",
    element: <Home />,
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/orgManagement",
    element: <OrgManagement />,
  },
  {
    path: "/logManagement",
    element: <LogManagement />,
  },

  {
    path: "/ForgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/WorkManagement",
    element: <WorkManagement />,
  },
  {
    path: "/WorkManagement",
    element: <WorkManagement />,
  },
  {
    path: "/reset-password/:id",
    element: <NewPassword />,
  },
];
