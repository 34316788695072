import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";

import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { getOrgMgmtDetails, activateOrg } from "../redusers/orgMgmtThunk";
import DeleteOrg from "./deleteOrg";
import { ToastContainer, toast } from "react-toastify";

const OrgManagement = () => {
  const dispatch = useDispatch();
  const [organizationsList, setOrganizations] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [deleteSelectedOrg, setDelete] = React.useState(false);

  useEffect(() => {
    dispatch(getOrgMgmtDetails())
      .unwrap()
      .then((resdata) => {
        setOrganizations(resdata?.response.orgs);
      });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deleteOrgData = (params) => {
    setSelectedOrg(params.row);
    setDelete(true);
  };

  const activateOrgData = (params) => {
    dispatch(activateOrg(params.row.hospital_id))
      .unwrap()
      .then(() => {
        toast.success("Organization Activated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        dispatch(getOrgMgmtDetails())
          .unwrap()
          .then((resdata) => {
            setOrganizations(resdata?.response.orgs);
          });
      });
  };
  const closeDialog = () => {
    setDelete(false);
  };

  const columns = [
    {
      field: "orgName",
      headerName: "Org Name",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.hospital_name}</span>
      ),
    },
    {
      field: "users",
      headerName: "No Of Users",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.usersCount}</span>
      ),
    },
    {
      field: "bmrs",
      headerName: "No Of BMRs",
      width: 110,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.bmrCount}</span>
      ),
    },
    {
      field: "readings",
      headerName: "No Of Readings",
      width: 80,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.readingCount}</span>
      ),
    },
    {
      field: "action",
      headerName: "Action Buttons",
      width: 80,
      flex: 1,
      renderCell: (params) => (
        <strong>
          {/* <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: 10,
              background: "#6572E4",
              //background: "#F98F60",
              borderRadius: 4,
              fontSize: 10,
              boxShadow: "none",
              textTransform: "none",
            }}
            className="view_Reports"
            onClick={() => handleClickOpen()}
          >
            Export
          </Button> */}
          {params.row.active_flag ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#FF6347",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => deleteOrgData(params)}
            >
              Delete
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                //background: "#F98F60",

                background: "#008000",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => activateOrgData(params)}
            >
              Activate
            </Button>
          )}
        </strong>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="dataoutlet2">
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Card variant="none">
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <p className="dashboard-title">
                      Organization Management Listing
                    </p>
                  </Grid>
                </Grid>
                <Box sx={{ height: 600, width: "100%" }}>
                  <DataGrid
                    rows={organizationsList}
                    columns={columns}
                    showCellRightBorder={true}
                    showColumnRightBorder={true}
                    getRowId={(row) => row.hospital_id}
                    pageSize={10}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      {deleteSelectedOrg && (
        <DeleteOrg
          selectedOrg={selectedOrg}
          open={deleteSelectedOrg}
          onClose={closeDialog}
        />
      )}
    </React.Fragment>
  );
};

export default OrgManagement;
