import { createSlice } from "@reduxjs/toolkit";
import * as LoginThunk from "./loginThunk";

const initialState = {
  userProfile: {},
  logindetail: {},
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: {
    [LoginThunk.login.fulfilled]: (state, action) => {
      if (action.payload.error) {
        state.errorMessage = action.payload.message;
      } else {
        state.userProfile1 = action.payload.response;
        state.errorMessage = "";
      }

      // console.log("User logged in");
    },
    [LoginThunk.verifyLoginOtp.fulfilled]: (state, action) => {
      if (action.payload.error) {
        state.errorMessage = action.payload.message;
      } else {
        state.loginOtp = action.payload.response;
        state.errorMessage = "";
      }
    },
    [LoginThunk.logout.fulfilled]: (state, action) => {
      state.userProfile = {};
    },

    [LoginThunk.loginResendOTP.fulfilled]: (state, action) => {
      state.loginOTP = action.payload.response;
    },
    [LoginThunk.forgotPassword.fulfilled]: (state, action) => {
      state.forgotPassword = action.payload.response;
    },
    [LoginThunk.resetPassword.fulfilled]: (state, action) => {
      state.resetPassword = action.payload.response;
    },
  },
});

export default loginSlice.reducer;
