import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Grid } from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDownCircleOutlined";
import "../../styles/header.scss";
import Popover from "@mui/material/Popover";
import Logo from "../../assets/images/logo.png";
import { TextField, InputAdornment, SvgIcon } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Buffer } from "buffer";

//import { loginDetailsByAdmin } from "../../../login/reducers/loginThunk";

const Search = styled("div")({
  position: "relative",
  marginLeft: 0,
  width: "50%",
});

const SearchIconWrapper = styled("div")({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledInputBase = styled(InputBase)({
  color: "inherit",
  width: "100%",
});

export default function PrimarySearchAppBar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentRoute, setCurrentRoute] = React.useState();

  useEffect(() => {
    const access_token = sessionStorage.getItem("access_token");
    const token = access_token ? access_token : "";

    /*  if (token) {
      window.location = "/Dashboard";
    } else {
      window.location = "/login";
    } */
  }, []);

  useEffect(() => {
    if (location && location.pathname) {
      switch (location.pathname) {
        case "/Dashboard":
          setCurrentRoute("Dashboard");
          break;
        case "/userManagement":
          setCurrentRoute("User Management");
          break;
        case "/orgManagement":
          setCurrentRoute("Organization Management");
          break;
        case "/logManagement":
          setCurrentRoute("Log Management");
          break;
        /*  case "/AdminListing":
          setCurrentRoute("User Management");
          break;
        case "/ReferralDoctor":
          setCurrentRoute("Referral Management");
          break;
        case "/NewBMRN":
          setCurrentRoute("Data Entry");
          break;
        case "/AddBMRN":
          setCurrentRoute("Data Entry");
          break;
        case "/dataentry":
          setCurrentRoute("Data Entry");
          break;
        case "/DoctorOpinion":
          setCurrentRoute("Data Entry");
          break;
        case "/Reports":
          setCurrentRoute("Reports");
          break;
        case "/AdminProfile":
          setCurrentRoute("User Profile");
          break; */
        default:
          setCurrentRoute("Dashboard");
      }
    }

    /*  if (location && location.pathname !== "/Dashboard") {
      dispatch(updateGlobalSearch(""));
      setSearchString("");
    } */
  }, [location.pathname]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={0} position="static" id="header" className="colored">
        <Toolbar>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="Data_Entry">{currentRoute}</div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="Data_Entry">Super Admin Portal</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={7}></Grid>
                    <Grid item xs={12} sm={2} id="profilename"></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
