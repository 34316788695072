import { createSlice } from "@reduxjs/toolkit";
import * as DashboardThunk from "../reducers/DashboardThunk";

const initialState = {
  activityList: {},
  ActivityTypes: {},
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: {
    [DashboardThunk.getActivityDetails.fulfilled]: (state, action) => {
      state.activityList = action.payload.response;
    },
    [DashboardThunk.getActivityTypes.fulfilled]: (state, action) => {
      state.ActivityTypes = action.payload.response;
    },
    [DashboardThunk.getOrganizations.fulfilled]: (state, action) => {
      state.Organizations = action.payload.response;
    },
    [DashboardThunk.getAllUsers.fulfilled]: (state, action) => {
      state.AllUsers = action.payload.response;
    },
    [DashboardThunk.getCoupons.fulfilled]: (state, action) => {
      state.Coupons = action.payload.response;
    },
    [DashboardThunk.getReports.fulfilled]: (state, action) => {
      state.getReports = action.payload.response;
    },
    [DashboardThunk.createCoupon.fulfilled]: (state, action) => {
      state.createCoupon = action.payload.response;
    },
    [DashboardThunk.updateCoupon.fulfilled]: (state, action) => {
      state.updateCoupon = action.payload.response;
    },
    [DashboardThunk.deleteCoupon.fulfilled]: (state, action) => {
      state.deleteCoupon = action.payload.response;
    },
    [DashboardThunk.activateCoupon.fulfilled]: (state, action) => {
      state.activateCoupon = action.payload.response;
    },
    [DashboardThunk.getPaymentPlans.fulfilled]: (state, action) => {
      state.paymentPlans = action.payload.response;
    },

    [DashboardThunk.createPlan.fulfilled]: (state, action) => {
      state.createPlan = action.payload.response;
    },
    [DashboardThunk.updatePlan.fulfilled]: (state, action) => {
      state.updatePlan = action.payload.response;
    },
    [DashboardThunk.deletePlan.fulfilled]: (state, action) => {
      state.deletePlan = action.payload.response;
    },
    [DashboardThunk.activatePlan.fulfilled]: (state, action) => {
      state.activatePlan = action.payload.response;
    },
  },
});
export default dashboardSlice.reducer;
