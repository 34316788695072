import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Button,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import {
  getReports,
  getOrganizations,
} from "../../Dashboard/reducers/DashboardThunk";
import { JsonToExcel } from "react-json-to-excel";

import { DataGrid } from "@mui/x-data-grid";
const Reports = (props) => {
  const dispatch = useDispatch();
  const [reports, setReports] = React.useState([]);

  const [organizations, setOrganizations] = React.useState([]);
  const [selectedOrg, setSelectedOrg] = React.useState();
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(1, "Year").format()
  );
  const [toDate, setToDate] = React.useState(moment().format());
  const [isLoading, setIsLoading] = React.useState(false);

  const onOrgSelected = (e) => {
    const org_id = e.target.value;
    setSelectedOrg(e.target.value);
  };
  useEffect(() => {
    getReportsData();
  }, [fromDate, toDate, selectedOrg]);

  const getReportsData = () => {
    setIsLoading(true);
    let params;
    params = {
      start: moment(fromDate).format("MM-DD-YYYY"),
      end: moment(toDate).format("MM-DD-YYYY"),
    };
    const req = { org_id: selectedOrg, params };
    dispatch(getReports(req))
      .unwrap()
      .then((resdata) => {
        setReports(resdata?.response.records);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    dispatch(getOrganizations())
      .unwrap()
      .then((resdata) => {
        setOrganizations(resdata?.response.orgs);
      });
  }, []);

  const columns = [
    {
      field: "baby_medical_record_number",
      headerName: <span className="gridheader_font">BMRN</span>,
      width: 500,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.baby_medical_record_number}</span>
      ),
    },
    {
      field: "hospital_name",
      flex: 1,
      headerName: <span className="gridheader_font">Hospital Name</span>,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">{params.row.hospital_name}</span>
      ),
    },
    {
      field: "score",
      flex: 1,
      headerName: <span className="gridheader_font">Score</span>,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">{params.row.score}</span>
      ),
    },

    {
      field: "user_name",
      flex: 1,
      headerName: <span className="gridheader_font">User Name</span>,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">{params.row.user_name}</span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ToastContainer />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="dataoutlet">
          <div className="formSection">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Card variant="none">
                  <CardContent>
                    <form id="mother-medical-form">
                      <Grid
                        container
                        style={{
                          borderBottom: "0.8px solid rgba(101, 114, 228, 0.2)",
                        }}
                      >
                        <Grid item xs={12} sm={3}>
                          <p className="dashboard-title">Reports</p>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <label
                            htmlFor="From"
                            className="dataentry_label1"
                            style={{ display: "block" }}
                          >
                            Organization
                          </label>
                          <Select
                            labelId="Organization"
                            id="org"
                            name="org"
                            label=""
                            size="small"
                            className="select_usertype2"
                            value={selectedOrg}
                            onChange={onOrgSelected}
                          >
                            {organizations && organizations.length
                              ? organizations.map((num) => (
                                  <MenuItem value={num.hospital_id}>
                                    {num.hospital_name}
                                  </MenuItem>
                                ))
                              : undefined}
                          </Select>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <label
                            htmlFor="From"
                            className="dataentry_label1"
                            style={{ display: "block" }}
                          >
                            From
                          </label>
                          <DatePicker
                            id="from_month"
                            name="from_month"
                            minDate="01-01-2020"
                            maxDate={toDate}
                            value={fromDate}
                            inputFormat="DD-MM-YYYY"
                            className="dataentry_input"
                            onChange={(date) => {
                              setFromDate(date["$d"]);
                            }}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                className="dataentry_input"
                                helperText={null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <label
                            htmlFor="To"
                            className="dataentry_label1"
                            style={{ display: "block" }}
                          >
                            To
                          </label>
                          <DatePicker
                            id="to_month"
                            name="to_month"
                            size="small"
                            className="dataentry_input"
                            inputFormat="DD-MM-YYYY"
                            minDate={fromDate}
                            maxDate={moment().format()}
                            value={toDate}
                            onChange={(date) => {
                              setToDate(date["$d"]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                className="dataentry_input"
                                helperText={null}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <JsonToExcel
                            data={reports}
                            fileName="report"
                            variant="outlined"
                            className="branch_Add"
                            style={{
                              float: "right",
                              background: "rgb(76, 175, 80)",
                              padding: "10px 20px",
                              marginTop: "22px",
                              borderRadius: "12px",
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: "10px" }}>
                        <Grid item xs={12} sm={12} className="header">
                          {isLoading ? (
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                height: "480px",
                              }}
                            >
                              <Grid sm={1}>
                                <CircularProgress />
                              </Grid>
                            </Grid>
                          ) : (
                            <div style={{ height: 550, width: "100%" }}>
                              <DataGrid
                                rows={reports}
                                columns={columns}
                                pageSize={8}
                                rowsPerPageOptions={[10]}
                                showCellRightBorder={true}
                                showColumnRightBorder={true}
                                getRowId={(row) => row.study_id}
                                cellClassName="customCell"
                                components={{
                                  NoRowsOverlay: () => (
                                    <Stack
                                      height="100%"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      No Plans Found
                                    </Stack>
                                  ),
                                  NoResultsOverlay: () => (
                                    <Stack
                                      height="100%"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      No Plans Found
                                    </Stack>
                                  ),
                                }}
                              />
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default Reports;
