import React from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHomeOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Logo from "../assets/images/logo.png";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";

const Layout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const navigate = useNavigate();

  const logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("user");
    navigate("/login");
    window.location.reload();
  };

  return (
    <>
      <nav className="nav-position">
        <ul className="sidebar-ul">
          <li className="sidebar-menuitems">
            <Link to="/" className="sidenavbar">
              <img className="sidenavbar-logo" src={Logo} />
            </Link>
          </li>
          <li
            className={
              splitLocation[1] === "Dashboard" ? "active" : "nonactive"
            }
          >
            <Link to="/Dashboard" className="sidenavbar">
              <GridViewOutlinedIcon className="nav_fill" fontSize="large" />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                Dashboard
              </h6>
            </Link>
          </li>
          <li
            className={
              splitLocation[1] === "userManagement" ? "active" : "nonactive"
            }
          >
            <Link to="/userManagement" className="sidenavbar">
              <PersonAddOutlinedIcon className="nav_fill" fontSize="large" />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                User <br />
                Management
              </h6>
            </Link>
          </li>

          <li
            className={
              splitLocation[1] === "orgManagement" ? "active" : "nonactive"
            }
          >
            <Link to="/orgManagement" className="sidenavbar">
              <AddHomeOutlinedIcon className="nav_fill" fontSize="large" />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                Org <br />
                Management
              </h6>
            </Link>
          </li>

          <li
            className={
              splitLocation[1] === "logManagement" ? "active" : "nonactive"
            }
          >
            <Link to="/logManagement" className="sidenavbar">
              <AddHomeOutlinedIcon className="nav_fill" fontSize="large" />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                Log <br />
                Management
              </h6>
            </Link>
          </li>

          <li
            className={
              splitLocation[1] === "WorkManagement" ? "active" : "nonactive"
            }
          >
            <Link to="/WorkManagement" className="sidenavbar">
              <WorkHistoryOutlinedIcon className="nav_fill" fontSize="large" />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                Work Queue <br />
                Management
              </h6>
            </Link>
          </li>

          <li
            className={splitLocation[1] === "Coupons" ? "active" : "nonactive"}
          >
            <Link to="/Coupons" className="sidenavbar">
              <AddHomeOutlinedIcon className="nav_fill" fontSize="large" />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                Coupons
              </h6>
            </Link>
          </li>

          <li className={splitLocation[1] === "Plans" ? "active" : "nonactive"}>
            <Link to="/Plans" className="sidenavbar">
              <SellOutlinedIcon className="nav_fill" fontSize="large" />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                Plans
              </h6>
            </Link>
          </li>

          <li
            className={splitLocation[1] === "Reports" ? "active" : "nonactive"}
          >
            <Link to="/Reports" className="sidenavbar">
              <InsertChartOutlinedIcon className="nav_fill" fontSize="large" />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                Reports
              </h6>
            </Link>
          </li>
          <li className={splitLocation[1] === "/" ? "active" : "nonactive"}>
            <Link to="/" className="sidenavbar" onClick={logout}>
              <LogoutOutlinedIcon
                fontSize="large"
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                }}
              />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                Logout
              </h6>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};
export default Layout;
