import { createSlice } from "@reduxjs/toolkit";
import * as UserMgmtThunk from "../redusers/userThunk";
const initialState = {
  usersList: {},
};

const userSlice = createSlice({
  name: "userMgmt",
  initialState,
  extraReducers: {
    [UserMgmtThunk.getUserMgmtDetails.fulfilled]: (state, action) => {
      state.usersList = action.payload.response;
    },
    [UserMgmtThunk.deleteUserDetails.fulfilled]: (state, action) => {
      state.deleteUser = action.payload.response;
    },
    [UserMgmtThunk.activateUser.fulfilled]: (state, action) => {
      state.activateUser = action.payload.response;
    },
    [UserMgmtThunk.changePassword.fulfilled]: (state, action) => {
      state.changePassword = action.payload.response;
    },
  },
});
export default userSlice.reducer;
