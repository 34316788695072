import { createSlice } from "@reduxjs/toolkit";
import * as workMgmtThunk from "../redusers/workMgmtThunk";
const initialState = {
  PendingUsers: {},
};

const workMgmtSlice = createSlice({
  name: "workMgmt",
  initialState,
  extraReducers: {
    [workMgmtThunk.getPendingUsers.fulfilled]: (state, action) => {
      state.PendingUsers = action.payload.response;
    },
    [workMgmtThunk.getUserDetailsByVerificationKey.fulfilled]: (
      state,
      action
    ) => {
      state.UserDetails = action.payload.response;
    },

    [workMgmtThunk.downloadReport.fulfilled]: (state, action) => {
      state = action.payload.response;
    },

    [workMgmtThunk.approveUser.fulfilled]: (state, action) => {
      state = action.payload.response;
    },

    [workMgmtThunk.rejectUser.fulfilled]: (state, action) => {
      state = action.payload.response;
    },
  },
});
export default workMgmtSlice.reducer;
