import { createSlice } from "@reduxjs/toolkit";
import * as orgMgmtThunk from "../redusers/orgMgmtThunk";
const initialState = {
  orgList: {},
};

const orgMgmtSlice = createSlice({
  name: "orgMgmt",
  initialState,
  extraReducers: {
    [orgMgmtThunk.getOrgMgmtDetails.fulfilled]: (state, action) => {
      state.orgList = action.payload.response;
    },
    [orgMgmtThunk.deleteOrgDetails.fulfilled]: (state, action) => {
      state.deleteOrg = action.payload.response;
    },
    [orgMgmtThunk.activateOrg.fulfilled]: (state, action) => {
      state.activateOrg = action.payload.response;
    },
    [orgMgmtThunk.getReports.fulfilled]: (state, action) => {
      state.reports = action.payload.response;
    },
  },
});
export default orgMgmtSlice.reducer;
