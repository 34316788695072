import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import Download from "../../common/assets/images/Download.svg";
import { Link } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import { DataGrid } from "@mui/x-data-grid";
import { save } from "save-file";
import moment from "moment";
import {
  getPendingUsers,
  getUserDetailsByVerificationKey,
  downloadReport,
  approveUser,
  rejectUser,
} from "../redusers/workMgmtThunk";
import { ToastContainer, toast } from "react-toastify";

const WorkManagement = () => {
  const dispatch = useDispatch();
  const [pendingUsers, setPendingUsers] = React.useState([]);
  const [reports, setReports] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [deleteSelectedOrg, setDelete] = React.useState(false);
  const [userDetails, SetUserDetails] = React.useState(false);
  const verifyKey = pendingUsers?.verification_key;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getPendingUsers())
      .unwrap()
      .then((resdata) => {
        setPendingUsers(resdata?.response.users);
      });
  }, []);

  const handleClickOpen = (params) => {
    setOpen(true);
    dispatch(getUserDetailsByVerificationKey(params.row.verification_key))
      .unwrap()
      .then((resdata) => {
        SetUserDetails(resdata?.response);
      });
  };

  const downloadRep = (e, fileName) => {
    e.preventDefault();

    dispatch(downloadReport(fileName))
      .unwrap()
      .then(async (resdata) => {
        await save(resdata.Body, fileName);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const ApproveUser = () => {
    const user_id = userDetails?.user_id;
    const status = "Approved";
    const req = { user_id, status };
    dispatch(approveUser(req))
      .unwrap()
      .then(async (resdata) => {
        toast.success("User Approved Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  const RejectUser = () => {
    const user_id = userDetails?.user_id;
    const status = "Rejected";
    const req = { user_id, status };
    dispatch(rejectUser(req))
      .unwrap()
      .then(async (resdata) => {
        toast.success("User Rejected Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const columns = [
    {
      field: "users",
      headerName: "User Name",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.user_name}</span>
      ),
    },
    {
      field: "email_address",
      headerName: "Email Aaddress",
      width: 110,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.email_address}</span>
      ),
    },
    {
      field: "contact_number",
      headerName: "Contact Number",
      width: 80,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.contact_number}</span>
      ),
    },
    {
      field: "action",
      headerName: "Action Buttons",
      width: 80,
      flex: 1,
      renderCell: (params) => (
        <strong>
          {/* <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: 10,
              background: "#6572E4",
              //background: "#F98F60",
              borderRadius: 4,
              fontSize: 10,
              boxShadow: "none",
              textTransform: "none",
            }}
            className="view_Reports"
            //onClick={() => handleClickOpen()}
          >
            Export
          </Button> */}
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: 10,
              //background: "#6572E4",
              background: "#F98F60",
              borderRadius: 4,
              fontSize: 10,
              boxShadow: "none",
              textTransform: "none",
            }}
            className="view_Reports"
            onClick={() => handleClickOpen(params)}
          >
            View
          </Button>
        </strong>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="dataoutlet2">
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Card variant="none">
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <p className="dashboard-title">Pending Users Listing</p>
                  </Grid>
                </Grid>
                <Box sx={{ height: 600, width: "100%" }}>
                  <DataGrid
                    rows={pendingUsers}
                    columns={columns}
                    showCellRightBorder={true}
                    showColumnRightBorder={true}
                    getRowId={(row) => row.user_id}
                    pageSize={10}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "0.8px solid",
            borderColor: "rgba(101, 114, 228, 0.4)",
            maxWidth: "900px",
          }}
        >
          <span>
            <Link
              className="FIle-left"
              onClick={handleClose}
              style={{ marginRight: "20px" }}
            >
              <img
                src={Back}
                className="file-img-left"
                alt=""
                style={{ width: "12px" }}
              />
            </Link>
            View User
          </span>
          <Link className="FIle-right-extreme" onClick={handleClose}>
            <img src={Close} style={{ marginRight: "20px" }} alt="" />
          </Link>
        </DialogTitle>
        <DialogContent>
          <form id="mother-medical-form">
            <Grid container>
              <Grid item xs={12} sm={4}>
                <label
                  htmlFor="User Type"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  User Type
                </label>
                <TextField
                  id="user_type"
                  name="user_type"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  value={userDetails?.user_type}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label
                  htmlFor="User Name"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  User Name
                </label>
                <TextField
                  id="user_name"
                  name="user_name"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  value={userDetails?.user_name}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <label
                  htmlFor="User Type"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  User Mobile Number
                </label>
                <TextField
                  id="contact_number"
                  name="contact_number"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  value={userDetails?.contact_number}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={4}>
                <label
                  htmlFor="Email ID"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  User Email ID
                </label>
                <TextField
                  id="email_address"
                  name="email_address"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  value={userDetails?.email_address}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label
                  htmlFor="Profession"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Profession
                </label>
                <TextField
                  id="Profession"
                  name="Profession"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  value={userDetails?.profession}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label
                  htmlFor="Institution"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Institution Name
                </label>
                <TextField
                  id="Institution_name"
                  name="Institution_name"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  value={userDetails?.institution_name}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={8}>
                <label
                  htmlFor="address"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Address
                </label>
                <TextField
                  id="address"
                  name="address"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  value={userDetails?.address}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label
                  htmlFor="City"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  City
                </label>
                <TextField
                  id="city"
                  name="city"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  value={userDetails?.city}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label
                  htmlFor="state"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  State
                </label>
                <TextField
                  id="state"
                  name="state"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  value={userDetails?.state}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label
                  htmlFor="Pincode"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Pin Code
                </label>
                <TextField
                  id="pincode"
                  name="pincode"
                  label=""
                  variant="outlined"
                  className="dataentry_input"
                  size="small"
                  value={userDetails?.pincode}
                  disabled
                />
              </Grid>
              {/*  {userByVK?.s3_filename ? ( */}
              <Grid item xs={12} sm={8}>
                <label
                  htmlFor="state"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Download Document
                </label>
                <div className="verifyAccount-img">
                  <List className="verifyAccount-list">
                    <ListItem className="verifyAccount-listitem">
                      <Grid container>
                        <Grid item xs={12} sm={10}>
                          {userDetails?.s3_filename}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <ListItemIcon className="file-align">
                            <IconButton
                              aria-label="download"
                              onClick={(e) =>
                                downloadRep(e, userDetails?.s3_filename)
                              }
                            >
                              <img src={Download} className="DownLoad-img" />
                            </IconButton>
                          </ListItemIcon>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                </div>
              </Grid>
              {/*  ) : null} */}
            </Grid>

            <div className="formSection">
              <Grid container>
                <Grid className="continue">
                  <Button
                    variant="contained"
                    className="purple-add"
                    type="submit"
                    onClick={ApproveUser}
                    color="primary"
                  >
                    <span>Approve</span>
                  </Button>
                </Grid>
                <Grid className="continue">
                  <Button
                    variant="contained"
                    className="purple-add"
                    type="submit"
                    onClick={RejectUser}
                  >
                    <span>Reject</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default WorkManagement;
