import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";

import { DataGrid } from "@mui/x-data-grid";
import {
  getActivityDetails,
  getDashboardStatistics,
} from "../reducers/DashboardThunk";
import moment from "moment";

const columns = [
  {
    field: "institution_alias_name",
    headerName: "Organization name",
    width: 150,
    flex: 1,
    renderCell: (params) => (
      <span className="dadata">
        {params.row.institution_alias_name ? (
          <span>{params.row.institution_alias_name}</span>
        ) : (
          <span>{params.row.admin_hospital_name}</span>
        )}
      </span>
    ),
  },
  {
    field: "activity",
    headerName: "Activity Type",
    width: 150,
    flex: 1,
    renderCell: (params) => (
      <span className="dadata">{params.row.activity}</span>
    ),
  },
  {
    field: "user_name",
    headerName: "User",
    sortable: false,
    width: 160,
    flex: 2,
    renderCell: (params) => (
      <span className="dadata">{params.row.user_name}</span>
    ),
  },
  {
    field: "fullName1",
    headerName: "User Role",
    sortable: false,
    width: 160,
    flex: 2,
    renderCell: (params) => (
      <span className="dadata">{params.row.activity}</span>
    ),
  },
  {
    field: "createdAt",
    headerName: "Activity Date",
    type: "number",
    width: 110,
    flex: 1,
    renderCell: (params) => (
      <span className="dadata">
        {moment(params.row.createdAt).format("DD - MM - YY")}
      </span>
    ),
  },

  {
    field: "createdTime",
    headerName: "Activity Time",
    type: "number",
    width: 110,
    flex: 1,
    renderCell: (params) => (
      <span className="dadata">
        {moment(params.row.createdAt).format("hh:mm:ss")}
      </span>
    ),
  },
];

const DashboardScreen = () => {
  const dispatch = useDispatch();

  const [activities, setActivities] = React.useState([]);
  const [dashboardStatistics, setDashboardStatistics] = React.useState();

  const user = sessionStorage.getItem("user");

  useEffect(() => {
    //const user_id = user ? JSON.parse(user).user_id : "";
    const req = "";
    dispatch(getActivityDetails(req))
      .unwrap()
      .then((resdata) => {
        setActivities(resdata?.response.activities);
      });
  }, [user]);

  useEffect(() => {
    dispatch(getDashboardStatistics())
      .unwrap()
      .then((resdata) => {
        setDashboardStatistics(resdata?.response);
      });
  }, [user]);

  return (
    <React.Fragment>
      <div className="dataoutlet">
        <Grid container>
          <Grid item xs={12} sm={12}>
            <div className="formSection">
              <Card variant="none" style={{ background: "#eeeeee" }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={2}>
                      <Card
                        variant="none"
                        style={{ margin: "10px", padding: "0px" }}
                      >
                        <CardContent style={{ margin: "0px", padding: "10px" }}>
                          <div
                            className="dashboard-header-title"
                            style={{
                              textAlign: "center",
                              fontSize: "1.3em",
                              color: "#878787",
                            }}
                          >
                            Organizations :
                          </div>
                          <br />
                          <div
                            className="dashboard-header-value"
                            style={{ color: "#000000" }}
                          >
                            {dashboardStatistics?.orgs}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Card
                        variant="none"
                        style={{ margin: "10px", padding: "0px" }}
                      >
                        <CardContent style={{ margin: "0px", padding: "10px" }}>
                          <div
                            className="dashboard-header-title"
                            style={{
                              textAlign: "center",
                              fontSize: "1.3em",
                              color: "#878787",
                            }}
                          >
                            Users :
                          </div>
                          <br />
                          <div
                            className="dashboard-header-value"
                            style={{ color: "#000000" }}
                          >
                            {dashboardStatistics?.users}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Card
                        variant="none"
                        style={{ margin: "10px", padding: "0px" }}
                      >
                        <CardContent style={{ margin: "0px", padding: "10px" }}>
                          <div
                            className="dashboard-header-title"
                            style={{
                              textAlign: "center",
                              fontSize: "1.3em",
                              color: "#878787",
                            }}
                          >
                            Active Patients :
                          </div>
                          <br />
                          <div
                            className="dashboard-header-value"
                            style={{ color: "#000000" }}
                          >
                            {dashboardStatistics?.bmrs}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Card
                        variant="none"
                        style={{ margin: "10px", padding: "0px" }}
                      >
                        <CardContent style={{ margin: "0px", padding: "10px" }}>
                          <div
                            className="dashboard-header-title"
                            style={{
                              textAlign: "center",
                              fontSize: "1.3em",
                              color: "#878787",
                            }}
                          >
                            Discharged Patients :
                          </div>
                          <br />
                          <div
                            className="dashboard-header-value"
                            style={{ color: "#000000" }}
                          >
                            {dashboardStatistics?.discharged}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Card
                        variant="none"
                        style={{ margin: "10px", padding: "0px" }}
                      >
                        <CardContent style={{ margin: "0px", padding: "10px" }}>
                          <div
                            className="dashboard-header-title"
                            style={{
                              textAlign: "center",
                              fontSize: "1.3em",
                              color: "#878787",
                            }}
                          >
                            Sepsis Positive Patients :
                          </div>
                          <br />
                          <div
                            className="dashboard-header-value"
                            style={{ color: "#000000" }}
                          >
                            {dashboardStatistics?.positiveSepsis}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Card
                        variant="none"
                        style={{ margin: "10px", padding: "0px" }}
                      >
                        <CardContent style={{ margin: "0px", padding: "10px" }}>
                          <div
                            className="dashboard-header-title"
                            style={{
                              textAlign: "center",
                              fontSize: "1.3em",
                              color: "#878787",
                            }}
                          >
                            Readings :
                          </div>
                          <br />
                          <div
                            className="dashboard-header-value"
                            style={{ color: "#000000" }}
                          >
                            {dashboardStatistics?.readings}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>

        <div className="formSection">
          <Card variant="none">
            <CardContent>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <p className="dashboard-title">
                    Timeline of the last 10 activities
                  </p>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <div style={{ height: 618, width: "100%" }}>
                    <DataGrid
                      rows={activities}
                      columns={columns}
                      showCellRightBorder={true}
                      showColumnRightBorder={true}
                      pageSize={10}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardScreen;
