import React, { useState, useEffect } from "react";
import {
  Alert,
  Snackbar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../styles/login.scss";
import logo from "../assets/images/avyantra_logo.png";
import prelogo from "../assets/images/presco_logo.png";
import { login, logindetailsget } from "../reducers/loginThunk";
import { ToastContainer, toast } from "react-toastify";

import moment from "moment";

import Loginform from "./loginform";

const LoginScreen = (props) => {
  const [loginForm, setLoginForm] = React.useState({
    password: "",
    showPassword: false,
  });

  const user = useSelector((state) => state.user.userProfile);
  const patient = useSelector((state) => state.patient);
  const [errMsg, setErrMsg] = React.useState("");
  const [PrivacyPolicy, setPrivacyPolicyOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);

  const handleClickShowPassword = () => {
    setLoginForm({ ...loginForm, showPassword: !loginForm.showPassword });
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    dispatch(login(loginForm))
      .unwrap()
      .then((resdata) => {
        if (resdata.error === false) {
          navigate("/LoginOTP");
        } else {
          toast.error(resdata.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const handleFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.getAttribute("name");
    // console.log("Field Name is ");
    // console.log(fieldname);

    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);

    const newFormData = { ...loginForm };
    newFormData[fieldname] = fieldvalue;
    setLoginForm(newFormData);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className="avy_logo">
                <a
                  href="https://avyantra.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logo} className="avy-img" />
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="login_contact">
                <a
                  href="https://avyantra.com/#contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contactUs"
                >
                  <Button
                    style={{ color: "#6572E4" }}
                    className="login_contact_content1"
                  >
                    Contact us
                  </Button>
                </a>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <img src={prelogo} className="pre_img" />
              <div className="pre_caption">
                Neonatal Sepsis Prediction Platform
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="signUpBg">
        <Box className="login-container">
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              marginTop: "3%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              //columns={{ xs: 1, sm: 12, md: 6 }}
            >
              <Card variant="outlined" className="login_radius">
                <CardContent
                  style={{
                    padding: "0.5rem 1rem",
                  }}
                >
                  <p className="login_welcome">WELCOME!</p>
                  <p className="login_cont">Log in to continue</p>
                  <form id="mother-medical-form" onSubmit={loginSubmit}>
                    <Grid
                      container
                      spacing={2}
                      justifyContent={"center"}
                      style={{
                        marginTop: "3%",
                      }}
                    >
                      <Grid item xs={12}>
                        <label
                          htmlFor="Email Address | Phone Number"
                          className="login_label"
                          style={{
                            display: "block",
                            textAlign: "left",
                            marginLeft: 0,
                          }}
                        >
                          Email Address | Phone Number
                          {/* User Email Id | Phone Number */}
                        </label>
                        <TextField
                          id="email_address"
                          name="email_address"
                          label=""
                          variant="outlined"
                          className="login-dataEntry-input"
                          size="small"
                          value={loginForm.email_address}
                          onChange={handleFormChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      justifyContent={"center"}
                      style={{
                        marginTop: "3%",
                      }}
                    >
                      <Grid item xs={12}>
                        <label
                          htmlFor="Reenter password"
                          className="login_label"
                          style={{
                            display: "block",
                            textAlign: "left",
                            marginTop: "10%",
                          }}
                        >
                          Password
                        </label>
                        <TextField
                          id="password"
                          name="password"
                          label=""
                          variant="outlined"
                          className="login-dataEntry-input"
                          size="small"
                          type={loginForm.showPassword ? "text" : "password"}
                          value={loginForm.password}
                          onChange={handleFormChange}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onClick={() => handleClickShowPassword()}
                              >
                                {loginForm.showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            ),
                          }}
                        />
                        
                        <Link to="/ForgotPassword" className="frgtpwdlink">
                          Forgot Password?
                        </Link>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "5%",
                            marginBottom: "5%",
                          }}
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            className="signup_button"
                          >
                            Log In
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Grid className="copy-posi">
          <p className="Copyright">
            Copyright © 2019-2024 Avyantra Health Technologies. All Rights
            Reserved
          </p>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default LoginScreen;
