import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Card, CardContent, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import {
  getActivityDetails,
  getActivityTypes,
  getOrganizations,
  getAllUsers,
  getAllUsersByOrg
} from "../../Dashboard/reducers/DashboardThunk";
import moment from "moment";

const columns = [
  {
    field: "institution_alias_name",
    headerName: "Organization name",
    width: 150,
    flex: 1,
    renderCell: (params) => (
      <span className="dadata">{params.row.institution_alias_name}</span>
    ),
  },
  {
    field: "activity",
    headerName: "Activity Type",
    width: 150,
    flex: 1,
    renderCell: (params) => (
      <span className="dadata">{params.row.activity}</span>
    ),
  },
  {
    field: "user_name",
    headerName: "User",
    sortable: false,
    width: 160,
    flex: 2,
    renderCell: (params) => (
      <span className="dadata">{params.row.user_name}</span>
    ),
  },
  {
    field: "fullName1",
    headerName: "User Role",
    sortable: false,
    width: 160,
    flex: 2,
    renderCell: (params) => (
      <span className="dadata">{params.row.activity}</span>
    ),
  },
  {
    field: "createdAt",
    headerName: "Activity Time",
    type: "number",
    width: 110,
    flex: 1,
    renderCell: (params) => (
      <span className="dadata">
        {moment(params.row.createdAt).format("DD - MM - YY")}
        <div
          className="dadata"
          style={{ textAlign: "center", color: "#6A6A6A", fontWeight: 400 }}
        >
          {moment(params.row.createdAt).format("hh:mm:ss")}
        </div>
      </span>
    ),
  },
];

export default function LogManagement() {
  const dispatch = useDispatch();

  const [activities, setActivities] = React.useState([]);
  const [activityTypes, setActivityTypes] = React.useState([]);
  const [organizations, setOrganizations] = React.useState([]);
  const [allUsers, setAllUsers] = React.useState([]);
  const [allUsersByOrg, setAllUsersByOrg] = React.useState([]);
  const [selectedOrg, setSelectedOrg] = React.useState(-1);
  const [selectedUser, setSelectedUser] = React.useState(-1);
  const [selectedActivity, setSelectedActivity] = React.useState(-1);

  const onOrgSelected = (e) => {
    const org_id = e.target.value;
    setSelectedOrg(e.target.value);
  };
  const onActivitySelected = (e) => {
    const activity_id = e.target.value;
    setSelectedActivity(e.target.value);
  };

  const onUserSelected = (e) => {
    const user_id = e.target.value;
    setSelectedUser(e.target.value);
  };

  useEffect(() => {
    getDashboardData();
  }, [selectedOrg, selectedUser, selectedActivity]);

  const getDashboardData = () => {
    const req = {};
    if (selectedOrg && selectedOrg != -1) {
      req.org_id = selectedOrg;
    }
    if (selectedUser && selectedUser != -1) {
      req.user_id = selectedUser;
    }

    if (selectedActivity && selectedActivity != -1) {
      req.activity_id = selectedActivity;
    }
    dispatch(getActivityDetails(req))
      .unwrap()
      .then((resdata) => {
        setActivities(resdata?.response.activities);
      });
  };

  /*  useEffect(() => {
    dispatch(getActivityDetails())
      .unwrap()
      .then((resdata) => {
        setActivities(resdata?.response.activities);
      });
  }, []); */

  useEffect(() => {
    dispatch(getActivityTypes())
      .unwrap()
      .then((resdata) => {
        setActivityTypes(
          resdata?.response.activities.map((type) => {
            return {
              label: type,
              value: type,
            };
          })
        );
      });
  }, []);

  useEffect(() => {
    dispatch(getOrganizations())
      .unwrap()
      .then((resdata) => {
        setOrganizations(resdata?.response.orgs);
      });
  }, []);

  /* useEffect(() => {
    dispatch(getAllUsers())
      .unwrap()
      .then((resdata) => {
        setAllUsers(resdata?.response.users);
      });
  }, []); */
  
  useEffect(() => {
    const req = {};
    if (selectedOrg && selectedOrg != -1) {
    req.hospital_id = selectedOrg;
    console.log('llllllllllllllll',req)
    dispatch(getAllUsersByOrg(req))
      .unwrap()
      .then((resdata) => {
        setAllUsers(resdata?.response.users);
      });
    }
  }, [selectedOrg]);
  return (
    <React.Fragment>
      <div className="dataoutlet">
        <div className="formSection">
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Card variant="none">
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={2}>
                      <p className="dashboard-title">Log Management Listing</p>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Select
                        labelId="Branch"
                        id="Bramch"
                        name="Bramch"
                        label=""
                        size="small"
                        className="select_usertype2"
                        value={selectedActivity}
                        onChange={onActivitySelected}
                      >
                        <MenuItem value="-1">Activity Type</MenuItem>
                        {activityTypes
                          ? activityTypes.map((type) => (
                              <MenuItem value={type.value}>
                                {type.label}
                              </MenuItem>
                            ))
                          : undefined}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Select
                        labelId="Ward"
                        id="ward"
                        name="ward"
                        label=""
                        size="small"
                        className="select_usertype2"
                        value={selectedOrg}
                        onChange={onOrgSelected}
                      >
                        <MenuItem value="-1">Org Name</MenuItem>
                        {organizations && organizations.length
                          ? organizations.map((num) => (
                              <MenuItem value={num.hospital_id}>
                                {num.hospital_name}
                              </MenuItem>
                            ))
                          : undefined}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Select
                        labelId="Ward"
                        id="ward"
                        name="ward"
                        label=""
                        size="small"
                        className="select_usertype2"
                        value={selectedUser}
                        onChange={onUserSelected}
                      >
                        <MenuItem value="-1">User Name</MenuItem>
                        {allUsers && allUsers.length
                          ? allUsers.map((num) => (
                              <MenuItem value={num.user_id}>
                                {num.user_name}
                              </MenuItem>
                            ))
                          : undefined}
                      </Select>
                    </Grid>
                  </Grid>
                  <Box sx={{ height: 600, width: "100%" }}>
                    <DataGrid
                      rows={activities}
                      columns={columns}
                      showCellRightBorder={true}
                      showColumnRightBorder={true}
                      pageSize={10}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}
