import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../api/apiCommonService";
export const getUserMgmtDetails = createAsyncThunk(
  "getUserMgmtDetails",
  async (req) => {
    const res = await DataService.getUserMgmtDetails(req);
    return res.data;
  }
);

export const deleteUserDetails = createAsyncThunk(
  "deleteUserDetails",
  async (user_id) => {
    const res = await DataService.deleteUserDetails(user_id);
    return res.data;
  }
);

export const activateUser = createAsyncThunk(
  "activateUser",
  async (user_id) => {
    const res = await DataService.activateUser(user_id);
    return res.data;
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async (user_id) => {
    const res = await DataService.changePassword(user_id);
    return res.data;
  }
);
