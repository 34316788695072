import { axiosInstance } from "./http-common";
import axios from "axios";

//const scoreUrl = "http://127.0.0.1:5000";

const getActivityDetails = (req) => {
  return axiosInstance.get(
    `super-admin/activities?start=0&limit=1000&sortBy=createdAt&sortOrder=DESC${
      req.org_id ? "&org_id=" + req.org_id : ""
    }${req.user_id ? "&user_id=" + req.user_id : ""}${
      req.activity_id ? "&activity=" + req.activity_id : ""
    }`,
    {
      title: "getActivityDetails",
    }
  );
};
const getDashboardStatistics = (req) => {
  return axiosInstance.get(`super-admin/dashboard-statics`, {
    title: "getDashboardStatistics",
  });
};

const getReports = (req) => {
  return axiosInstance.get(
    `super-admin/reports?${req.org_id ? "&org_id=" + req.org_id : ""}&start=${
      req.params.start
    }&end=${req.params.end}`,
    {
      title: "getReports",
    }
  );
};
const getUserMgmtDetails = (req) => {
  return axiosInstance.get(
    `super-admin/users?start=0&limit=1000&sortBy=createdAt&sortOrder=DESC`,
    {
      title: "getUserMgmtDetails",
    }
  );
};
const getOrgMgmtDetails = (req) => {
  return axiosInstance.get(
    `super-admin/orgs?start=0&limit=1000&sortBy=createdAt&sortOrder=DESC`,
    {
      title: "getOrgMgmtDetails",
    }
  );
};
const getPendingUsers = (req) => {
  return axiosInstance.get(
    `super-admin/work-queue?start=0&limit=100&sortBy=createdAt&sortOrder=DESC`,
    {
      title: "getPendingUsers",
    }
  );
};
const login = (data) => {
  return axiosInstance.post(`/super-admin/login`, data, {
    title: "PatientLogin",
  });
};

const forgotPassword = (data) => {
  return axiosInstance.post(`/super-admin/forgotPassword`, data);
};
const resetPassword = (data) => {
  return axiosInstance.post(`/super-admin/resetPassword`, data);
};

const verifyLoginOtp = (req) => {
  return axiosInstance.post(`/verify-login-otp`, req, {
    title: "PatientLogin",
  });
};

const deleteUserDetails = (user_id) => {
  return axiosInstance.delete(`super-admin/user/${user_id}`);
};
const activateUser = (user_id) => {
  return axiosInstance.put(`super-admin/user/activate/${user_id}`);
};

const getActivityTypes = (req) => {
  return axiosInstance.get(`super-admin/activity-types`, req, {
    title: "PatientLogin",
  });
};

const getAllUsers = (req) => {
  return axiosInstance.get(
    `super-admin/all-users?start=0&limit=1000&sortBy=createdAt&sortOrder=DESC`,
    req,
    {
      title: "getAllUsers",
    }
  );
};

const getAllUsersByOrg = (req) => {
  return axiosInstance.get(
    `super-admin/all-usersByOrg/${req.hospital_id}`,
    req,
    {
      title: "getAllUsersByOrg",
    }
  );
};

const getOrganizations = (req) => {
  return axiosInstance.get(
    `super-admin/all-orgs?start=0&limit=1000&sortBy=createdAt&sortOrder=DESC`,
    req,
    {
      title: "getOrganizations",
    }
  );
};

const deleteOrgDetails = (org_id) => {
  return axiosInstance.delete(`super-admin/org/${org_id}`);
};
const activateOrg = (org_id) => {
  return axiosInstance.put(`super-admin/org/activate/${org_id}`);
};
const changePassword = (user_id) => {
  return axiosInstance.put(`super-admin/user/reset-password/${user_id}`);
};

const getUserDetailsByVerificationKey = (verifyKey) => {
  return axiosInstance.get(
    `hospitalBranch/getUserDetailsByVerificationKey/${verifyKey}`
  );
};
const downloadReport = (filename) => {
  return axiosInstance.get(`file/download/${filename}`);
};

const approveUser = (req) => {
  return axiosInstance.put(`/hospitalBranch/user/status`, req);
};

const rejectUser = (req) => {
  return axiosInstance.put(`/hospitalBranch/user/status`, req);
};
const getCoupons = (req) => {
  return axiosInstance.get(`coupons/list`, req, {
    title: "getCoupons",
  });
};
const createCoupon = (req) => {
  return axiosInstance.post(`coupons/create`, req, {
    title: "createCoupon",
  });
};

const updateCoupon = (req) => {
  return axiosInstance.put(`coupons/update`, req, {
    title: "updateCoupon",
  });
};

const deleteCoupon = (coupon_id) => {
  return axiosInstance.delete(`coupons/delete/${coupon_id}`);
};

const activateCoupon = (coupon_id) => {
  return axiosInstance.put(`coupons/activate/${coupon_id}`);
};

const getPaymentPlans = (req) => {
  return axiosInstance.get(`payments/plans`, req, {
    title: "getPaymentPlans",
  });
};
const createPlan = (req) => {
  return axiosInstance.post(`payments/plan`, req, {
    title: "createPlan",
  });
};

const updatePlan = (req) => {
  return axiosInstance.put(`payments/plan`, req, {
    title: "updatePlan",
  });
};
const deletePlan = (plan_id) => {
  return axiosInstance.delete(`payments/plan/${plan_id}`);
};
const activatePlan = (plan_id) => {
  return axiosInstance.put(`payments/plan/activate/${plan_id}`);
};

const CommonService = {
  login,
  verifyLoginOtp,
  getActivityDetails,
  getUserMgmtDetails,
  getOrgMgmtDetails,
  deleteUserDetails,
  activateUser,
  activateOrg,
  deleteOrgDetails,
  changePassword,
  getDashboardStatistics,
  getPendingUsers,
  getActivityTypes,
  getAllUsers,
  getAllUsersByOrg,
  getOrganizations,
  getUserDetailsByVerificationKey,
  downloadReport,
  approveUser,
  rejectUser,
  getCoupons,
  createCoupon,
  updateCoupon,
  getPaymentPlans,
  getReports,
  updatePlan,
  createPlan,
  deleteCoupon,
  activateCoupon,
  deletePlan,
  activatePlan,
  forgotPassword,
  resetPassword
};

export default CommonService;
