import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Link } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import CsvDownloadButton from "react-json-to-csv";
import DeleteCoupon from "./DeleteCoupon";
const ALPHA_NUMERIC = /^[a-zA-Z0-9- ]+$/;

import { ToastContainer, toast } from "react-toastify";
import {
  getCoupons,
  createCoupon,
  updateCoupon,
  activateCoupon,
} from "../../Dashboard/reducers/DashboardThunk";

import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
const Coupons = (props) => {
  const dispatch = useDispatch();

  const Coupon = useSelector((state) => state.dashboard?.Coupons);
  const [CouponsList, setCoupons] = React.useState([]);
  const [addCouponData, setCouponData] = useState();
  const [startDate, setStartDate] = React.useState(moment().format());
  const [expiryDate, setExpiryDate] = React.useState(moment().format());
  const [SelectedCoupon, setSelectedCoupon] = useState([]);
  const [deleteSelectedCoupon, setDelete] = React.useState(false);

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;

    const fieldvalue = event.target.value;
    const newFormData = { ...addCouponData };
    newFormData[fieldname] = fieldvalue;
    setCouponData(newFormData);
  };

  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    const newFormData = { ...addCouponData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    if (!addCouponData?.coupon_id) {
      const req = newFormData;
      dispatch(createCoupon(req))
        .unwrap()
        .then((resdata) => {
          if (resdata.status == "200") {
            dispatch(getCoupons())
              .unwrap()
              .then((resdata) => {
                setCoupons(resdata?.response.coupons);
              });
            toast.success("Coupon Created Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setsuccessMessage(resdata.message));
            setOpen(false);
          } else {
            toast.error(resdata.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setErrorMessage(resdata.message));
          }
        });
    } else if (addCouponData?.coupon_id) {
      const req = newFormData;
      dispatch(updateCoupon(req))
        .unwrap()
        .then((resdata) => {
          if (resdata.status == "200") {
            dispatch(getCoupons())
              .unwrap()
              .then((resdata) => {
                setCoupons(resdata?.response.coupons);
              });
            toast.success("Coupon Updated Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setsuccessMessage(resdata.message));
            setOpen(false);
          } else {
            toast.error(resdata.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setErrorMessage(resdata.message));
          }
        });
    }
  };

  useEffect(() => {
    dispatch(getCoupons())
      .unwrap()
      .then((resdata) => {
        setCoupons(resdata?.response.coupons);
      });
  }, []);

  const [open, setOpen] = React.useState(false);
  const [errorFields, setErrorFields] = useState({});
    const [showMaxUsersErrorMessage, setShowMaxUsersErrorMessage] = useState();
    const [showDiscountErrorMessage, setShowDiscountErrorMessage] = useState();
    const [showNameErrorMessage, setShowNameErrorMessage] = useState();
    const [showCodeErrorMessage, setShowCodeErrorMessage] = useState();
    const [isValid, setValid] = React.useState(true);



  const verifyMaxUsers = (event) => {
    if (event.target.value >= 1 && event.target.value <= 100) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { max_users: false })
      );
      setShowMaxUsersErrorMessage("");
    } else {
      setShowMaxUsersErrorMessage(
        "Max number of users should be between 1 - 100"
      );
      setErrorFields(
        Object.assign({}, errorFields, { max_users: true })
      );
    }
  };
  const verifyDiscount = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { discount_in_percentage: false })
      );
      setShowDiscountErrorMessage("");
    } else {
      setShowDiscountErrorMessage(
        "Discount should be between 0 - 100"
      );
      setErrorFields(
        Object.assign({}, errorFields, { discount_in_percentage: true })
      );
    }
  };

  useEffect(() => {
      verifyErrorFields();
    }, [errorFields]);
  
    const verifyErrorFields = async () => {
      let enableSubmitButton = true;
      const keys = Object.keys(errorFields);
      keys.map((key) => {
        errorFields[key] !== false ? (enableSubmitButton = false) : null;
      });
      setValid(enableSubmitButton);
    };

  const closeDialog = () => {
    setOpen(false);
    setDelete(false);
    dispatch(getCoupons())
      .unwrap()
      .then((resdata) => {
        setCoupons(resdata?.response.coupons);
      });
  };

  const activateCouponId = (params) => {
    dispatch(activateCoupon(params.row.coupon_id))
      .unwrap()
      .then(() => {
        toast.success("Coupon Activated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        dispatch(getCoupons())
          .unwrap()
          .then((resdata) => {
            setCoupons(resdata?.response.coupons);
          });
      });
  };

  const handleClose = () => {
    setOpen(false);
    setCouponData();
  };

  const handleOpen = () => {
    setOpen(true);
    setCouponData();
  };

  const handleClickOpen = (params) => {
    const data = CouponsList.filter(
      (d) => d.coupon_id === params.row.coupon_id
    );
    setCouponData(data[0]);
    setOpen(true);
  };
  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };
  const deleteCoupon = (params) => {
    setSelectedCoupon(params.row);
    setDelete(true);
    dispatch(getCoupons())
      .unwrap()
      .then((resdata) => {
        setCoupons(resdata?.response.coupons);
      });
  };
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const columns = [
    {
      field: "name",
      headerName: <span className="gridheader_font">Coupon Name</span>,
      width: 500,
      flex: 1,
      renderCell: (params) => <span className="dadata">{params.row.name}</span>,
    },
    {
      field: "code",
      flex: 1,
      headerName: <span className="gridheader_font">Code</span>,
      width: 150,
      renderCell: (params) => <span className="dadata">{params.row.code}</span>,
    },
    {
      field: "start_date",
      flex: 1,
      headerName: <span className="gridheader_font">Start Date</span>,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">
          {moment(params.row.start_date).format("DD - MM - YY")}
        </span>
      ),
    },
    {
      field: "expiry_date",
      flex: 1,
      headerName: <span className="gridheader_font">Expiry Date</span>,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">
          {moment(params.row.expiry_date).format("DD - MM - YY")}
        </span>
      ),
    },
    {
      field: "discount_in_percentage",
      flex: 1,
      headerName: <span className="gridheader_font">Discount (%)</span>,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">{params.row.discount_in_percentage}</span>
      ),
    },
    {
      field: "max_users",
      flex: 1,
      headerName: <span className="gridheader_font">Max Users</span>,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">{params.row.max_users}</span>
      ),
    },
    {
      field: "used_users",
      flex: 1,
      headerName: <span className="gridheader_font">Used Users</span>,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">{params.row.used_users}</span>
      ),
    },

    {
      field: "fullName",
      headerName: "Action Buttons",
      width: 80,
      flex: 1,
      renderCell: (params) => (
        <strong>
          <>
          {params.row.active_flag ? (
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: 10,
              background: "#6572E4",
              //background: "#F98F60",
              borderRadius: 4,
              fontSize: 10,
              boxShadow: "none",
              textTransform: "none",
            }}
            className="view_Reports"
            onClick={() => handleClickOpen(params)}
          >
            Edit
          </Button>
          ):(
            
          <Button
          variant="contained"
          size="small"
          style={{
            marginLeft: 10,
            background: "gray",
            //background: "#F98F60",
            borderRadius: 4,
            fontSize: 10,
            boxShadow: "none",
            textTransform: "none",
          }}
          className="view_Reports"
          //onClick={() => handleClickOpen(params)}
        >
          Edit
        </Button>
          )}
          </>
          {params.row.active_flag ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#FF6347",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => deleteCoupon(params)}
            >
              Delete
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                //background: "#F98F60",

                background: "#008000",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => activateCouponId(params)}
            >
              Deleted
            </Button>
          )}
        </strong>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="dataoutlet">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="formSection">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Card variant="none">
                  <CardContent>
                    <form id="mother-medical-form">
                      <Grid
                        container
                        style={{
                          borderBottom: "0.8px solid rgba(101, 114, 228, 0.2)",
                        }}
                      >
                        <Grid item xs={12} sm={10}>
                          <p className="dashboard-title">List Of Coupons</p>
                        </Grid>
                        <Grid item xs={12} sm={2} className="header">
                          <Button
                            variant="outlined"
                            className="branch_Add"
                            onClick={handleOpen}
                            style={{ float: "right" }}
                          >
                            + Add Coupon
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: "10px" }}>
                        <Grid item xs={12} sm={12} className="header">
                          <div style={{ height: 550, width: "100%" }}>
                            <DataGrid
                              rows={CouponsList}
                              columns={columns}
                              pageSize={8}
                              rowsPerPageOptions={[10]}
                              showCellRightBorder={true}
                              showColumnRightBorder={true}
                              getRowId={(row) => row.coupon_id}
                              cellClassName="customCell"
                              components={{
                                NoRowsOverlay: () => (
                                  <Stack
                                    height="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    No Coupons Found
                                  </Stack>
                                ),
                                NoResultsOverlay: () => (
                                  <Stack
                                    height="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    No Coupons Found
                                  </Stack>
                                ),
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Dialog open={open} onClose={handleClose}>
              <DialogTitle
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "0.8px solid",
                  borderColor: "rgba(101, 114, 228, 0.4)",
                }}
              >
                <span>
                  <Link
                    className="FIle-left"
                    onClick={handleClose}
                    style={{ marginRight: "20px" }}
                  >
                    <img
                      src={Back}
                      className="file-img-left"
                      alt=""
                      style={{ width: "12px" }}
                    />
                  </Link>
                  {!addCouponData?.coupon_id ? "Add" : "Update"} Coupon
                </span>
                <Link className="FIle-right-extreme" onClick={handleClose}>
                  <img src={Close} style={{ marginRight: "20px" }} alt="" />
                </Link>
              </DialogTitle>
              <DialogContent>
                <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <label
                        htmlFor="City"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Coupon Name
                      </label>
                      <TextField
                        id="name"
                        name="name"
                        label=""
                        required
                        variant="outlined"
                        className="dataentry_input"
                        size="small"
                        onChange={handleAddFormChange}
                        value={addCouponData?.name ? addCouponData?.name : ""}
                        onKeyDown={(event) => {
                          if (
                            !ALPHA_NUMERIC.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowNameErrorMessage(
                              "Only Numerics and Alphabets are acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                name: true,
                              })
                            );
                          }
                          else{
                            setShowNameErrorMessage();
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                name: false,
                              })
                            );
                          }
                        }}
                        error={
                          errorFields.name &&
                          showNameErrorMessage
                        }
                        helperText={
                          errorFields.name &&
                          showNameErrorMessage
                            ? showNameErrorMessage
                            : ""
                        }
                        //helperText={showErrorMessage}
                        errorText={showNameErrorMessage}
                        InputProps={{
                          inputProps: {
                            maxLength: 20,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label
                        htmlFor="City"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Coupon Code
                      </label>
                      <TextField
                        id="code"
                        name="code"
                        label=""
                        variant="outlined"
                        className="dataentry_input"
                        size="small"
                        required
                        onChange={handleAddFormChange}
                        value={addCouponData?.code ? addCouponData?.code : ""}
                        onKeyDown={(event) => {
                          if (
                            !ALPHA_NUMERIC.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowCodeErrorMessage(
                              "Only Numerics and Alphabets are acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                code: true,
                              })
                            );
                          }
                          else{
                            setShowCodeErrorMessage();
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                code: false,
                              })
                            );
                          }
                        }}
                        error={
                          errorFields.code &&
                          showCodeErrorMessage
                        }
                        helperText={
                          errorFields.code &&
                          showCodeErrorMessage
                            ? showCodeErrorMessage
                            : ""
                        }
                        //helperText={showErrorMessage}
                        errorText={showCodeErrorMessage}
                        InputProps={{
                          inputProps: {
                            maxLength: 20,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label
                        htmlFor=" Start Date"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Start Date
                      </label>
                      <DatePicker
                        id="start_date"
                        size="small"
                        name="start_date"
                      required
                        className="dataentry_input"
                        inputFormat="DD/MM/YYYY"
                        InputLabelProps={{ shrink: true }}
                        value={addCouponData?.start_date}
                        maxDate={moment(addCouponData?.expiry_date).format()}
                        onChange={(date) => {
                          setStartDate(date["$d"]);
                          handleAddFormChange({
                            target: { name: "start_date", value: date["$d"] },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            className="dataentry_input"
                            helperText={null}
                            onKeyDown={(e) => {
                              e.preventDefault();
                           }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label
                        htmlFor=" Start Date"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Expiry Date
                      </label>
                      <DatePicker
                        id="expiry_date"
                        size="small"
                        name="expiry_date"
                        className="dataentry_input"
                        inputFormat="DD/MM/YYYY"
                        InputLabelProps={{ shrink: true }}
                        required
                        //maxDate={moment().format()}
                        value={addCouponData?.expiry_date}
                        minDate={addCouponData?.start_date}
                        onChange={(date) => {
                          setExpiryDate(date["$d"]);
                          handleAddFormChange({
                            target: { name: "expiry_date", value: date["$d"] },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            className="dataentry_input"
                            helperText={null}
                            onKeyDown={(e) => {
                              e.preventDefault();
                           }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label
                        htmlFor="Max Users"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Max Users
                      </label>
                      <TextField
                        id="max_users"
                        name="max_users"
                        label=""
                        variant="outlined"
                      required
                        className="dataentry_input"
                        size="small"
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyMaxUsers(e);
                        }}
                        //onChange={handleAddFormChange}
                        value={
                          addCouponData?.max_users
                            ? addCouponData?.max_users
                            : ""
                        }
                        error={
                          errorFields.max_users &&
                          showMaxUsersErrorMessage
                        }
                        errorText={showMaxUsersErrorMessage}
                        helperText={
                          errorFields.max_users &&
                          showMaxUsersErrorMessage
                            ? showMaxUsersErrorMessage
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label
                        htmlFor="Max Users"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Discount (%)
                      </label>
                      <TextField
                        id="discount_in_percentage"
                        name="discount_in_percentage"
                        label=""
                        variant="outlined"
                        className="dataentry_input"
                        size="small"
                      required
                        //onChange={handleAddFormChange}
                        value={
                          addCouponData?.discount_in_percentage
                            ? addCouponData?.discount_in_percentage
                            : ""
                        }
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyDiscount(e);
                        }}
                        
                        error={
                          errorFields.discount_in_percentage &&
                          showDiscountErrorMessage
                        }
                        errorText={showDiscountErrorMessage}
                        helperText={
                          errorFields.discount_in_percentage &&
                          showDiscountErrorMessage
                            ? showDiscountErrorMessage
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>

                  <div className="formSection">
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      style={{ justifyContent: "center" }}
                    >
                      <Grid item xs={12} sm={4}>
                        <Button
                          variant="contained"
                          className="back"
                          onClick={handleClose}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {!addCouponData?.coupon_id ? (
                          <div>
                            {isValid ? (
                            <Button
                              variant="contained"
                              className="purple-add"
                              type="submit"
                              onClick={handleClick({
                                vertical: "top",
                                horizontal: "center",
                              })}
                            >
                              <span>Add Coupon</span>
                            </Button>
                            ):(
                              <Button
                              variant="contained"
                              className="back"
                              
                            >
                              <span>Add Coupon</span>
                            </Button>
                            )}
                          </div>
                        ) : (
                          <div>
                            {isValid ? (
                            <Button
                              variant="contained"
                              className="purple-add1"
                              type="submit"
                              onClick={handleClick({
                                vertical: "top",
                                horizontal: "center",
                              })}
                            >
                              <span>Update Coupon</span>
                            </Button>
                            ):(
                              <Button
                              variant="contained"
                              className="back"
                              
                            >
                              <span>Update Coupon</span>
                            </Button>
                            )}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </DialogContent>
            </Dialog>
          </div>

          {deleteSelectedCoupon && (
            <DeleteCoupon
              SelectedCoupon={SelectedCoupon}
              open={deleteSelectedCoupon}
              onClose={closeDialog}
            />
          )}
        </LocalizationProvider>
      </div>
    </React.Fragment>
  );
};

export default Coupons;
