import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../api/apiCommonService";
export const getOrgMgmtDetails = createAsyncThunk(
  "getOrgMgmtDetails",
  async (req) => {
    const res = await DataService.getOrgMgmtDetails(req);
    return res.data;
  }
);

export const deleteOrgDetails = createAsyncThunk(
  "deleteOrgDetails",
  async (org_id) => {
    const res = await DataService.deleteOrgDetails(org_id);
    return res.data;
  }
);

export const activateOrg = createAsyncThunk("activateOrg", async (org_id) => {
  const res = await DataService.activateOrg(org_id);
  return res.data;
});

export const getReports = createAsyncThunk("getReports", async (req) => {
  const res = await DataService.getReports(req);
  return res.data;
});
