import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../api/apiCommonService";
export const getPendingUsers = createAsyncThunk(
  "getPendingUsers",
  async (req) => {
    const res = await DataService.getPendingUsers(req);
    return res.data;
  }
);

export const getUserDetailsByVerificationKey = createAsyncThunk(
  "getUserDetailsByVerificationKey",
  async (verifyKey) => {
    const res = await DataService.getUserDetailsByVerificationKey(verifyKey);
    return res.data;
  }
);

export const downloadReport = createAsyncThunk(
  "downloadReport",
  async (req) => {
    const res = await DataService.downloadReport(req);
    return res.data;
  }
);

export const approveUser = createAsyncThunk("approveUser", async (req) => {
  const res = await DataService.approveUser(req);
  return res.data;
});

export const rejectUser = createAsyncThunk("rejectUser", async (req) => {
  const res = await DataService.rejectUser(req);
  return res.data;
});
