import React, { useState, useEffect } from "react";
import {
  Alert,
  Snackbar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { Outlet, Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../styles/login.scss";
import logo from "../assets/images/avyantra_logo.png";
import prelogo from "../assets/images/presco_logo.png";
import { ToastContainer, toast } from "react-toastify";


const NewPassword = (props) => {
  const [loginForm, setLoginForm] = React.useState({});
  const [profileData, setProfileData] = useState();

  const [showConfirmPasswordErrorMessage, setShowConfirmPasswordErrorMessage] =
    useState();
  const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState();
  const [errorFields, setErrorFields] = useState({});

  const verifyKey = useParams();
  const passcode = verifyKey.id;

  const user = useSelector((state) => state.user.userProfile);
  const patient = useSelector((state) => state.patient);
  const [errMsg, setErrMsg] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verifyConfirmPassword = (event) => {
    const Passwordcheck =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!Passwordcheck.test(event.target.value)) {
      setShowConfirmPasswordErrorMessage(
        "Password must contain a minimum of eight characters, including at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      setErrorFields(
        Object.assign({}, errorFields, { confirm_password: true })
      );
      FormChange(event);
    } else {
      FormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { confirm_password: false })
      );
      setShowConfirmPasswordErrorMessage("");
    }
  };

  const verifyPassword = (event) => {
    const Passwordcheck =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!Passwordcheck.test(event.target.value)) {
      setShowPasswordErrorMessage(
        "Password must contain a minimum of eight characters, including at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      setErrorFields(Object.assign({}, errorFields, { password: true }));
      FormChange(event);
    } else {
      FormChange(event);
      setErrorFields(Object.assign({}, errorFields, { password: false }));
      setShowPasswordErrorMessage("");
    }
  };

  const FormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);
    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);
    const newFormData = { ...profileData };
    newFormData[fieldname] = fieldvalue;
    // console.log("Field Value is ");
    // console.log(newFormData);
    setProfileData(newFormData);
  };

  const FormSubmit = (event) => {
    event.preventDefault();

    const newFormData = { ...profileData };
    newFormData.passcode = passcode;
    if (newFormData.password == newFormData.confirm_password) {
      const req = { newFormData };
      dispatch(resetPassword(newFormData)).then((resdata) => {
        if (resdata.payload.status != "200") {
          toast.error(resdata.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          //dispatch(setErrorMessage(resdata.payload.message));
        } else {
          //dispatch(setsuccessMessage(resdata.payload.message));
          toast.success(resdata.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          sessionStorage.removeItem("access_token");
          sessionStorage.removeItem("user");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      });
    } else {
      toast.error(PASSWORD_MISMATCH, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //dispatch(setErrorMessage(PASSWORD_MISMATCH));
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className="avy_logo">
                <a
                  href="https://avyantra.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logo} className="avy-img" />
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="login_contact">
                <a
                  href="https://avyantra.com/#contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contactUs"
                >
                  <Button
                    style={{ color: "#6572E4" }}
                    className="login_contact_content1"
                  >
                    Contact us
                  </Button>
                </a>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <img src={prelogo} className="pre_img" />
              <div className="pre_caption">
                Neonatal Sepsis Prediction Platform
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="signUpBg">
        <Box className="login-container">
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              marginTop: "3%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              //columns={{ xs: 1, sm: 12, md: 6 }}
            >
              <Card variant="outlined" className="login_radius">
                <CardContent>
                  <p className="login_welcome">Set new password</p>
                  <p className="forgot_cont">
                    Your new password must be different from previous passwords
                  </p>
                  <form id="mother-medical-form" onSubmit={FormSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <label
                          htmlFor="New Password"
                          className="dataentry_label"
                          style={{ display: "block" }}
                        >
                          Password
                        </label>
                        <TextField
                          id="password"
                          name="password"
                          label=""
                          variant="outlined"
                          className="login-dataEntry-input"
                          size="small"
                          type={"password"}
                          //onChange={FormChange}

                          error={
                            errorFields.password && showPasswordErrorMessage
                          }
                          //helperText={showErrorMessage}
                          errorText={showPasswordErrorMessage}
                          helperText={
                            errorFields.password && showPasswordErrorMessage
                              ? showPasswordErrorMessage
                              : ""
                          }
                          onChange={(e) => {
                            FormChange(e);
                            verifyPassword(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <label
                          htmlFor="Confirm New Password"
                          className="dataentry_label"
                          style={{ display: "block" }}
                        >
                          Confirm Password
                        </label>
                        <TextField
                          id="confirm_password"
                          name="confirm_password"
                          label=""
                          variant="outlined"
                          className="login-dataEntry-input"
                          size="small"
                          type={"password"}
                          error={
                            errorFields.confirm_password &&
                            showConfirmPasswordErrorMessage
                          }
                          //helperText={showErrorMessage}
                          errorText={showConfirmPasswordErrorMessage}
                          helperText={
                            errorFields.confirm_password &&
                            showConfirmPasswordErrorMessage
                              ? showConfirmPasswordErrorMessage
                              : ""
                          }
                          //onChange={FormChange}
                          onChange={(e) => {
                            FormChange(e);
                            verifyConfirmPassword(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "10%",
                          marginBottom: "10%",
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          className="sendcode_button"
                        >
                          Confirm
                        </Button>
                      </Grid>
                    </Grid>

                    {/*  <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p>
                      Don't have an account?
                      <Link to="/Signup">Sign Up</Link>
                    </p>
                  </Grid>
                </Grid> */}
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Grid className="copy-posi">
          <p className="Copyright">
            Copyright © 2019-2024 Avyantra Health Technologies. All Rights
            Reserved
          </p>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default NewPassword;
