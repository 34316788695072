import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userSlice from "../common/reducers/userSlice";
import loginSlice from "../login/reducers/loginSlice";
import userMgmtSlice from "../UserManagement/redusers/userMgmtSlice";
import dashboardSlice from "../Dashboard/reducers/dashboardSlice";
import OrgMgmtSlice from "../orgManagement/redusers/OrgmgmtSlice";
import workMgmtSlice from "../workManagement/redusers/workMgmtSlice";
import logger from "redux-logger";
/*const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

con*/

export const store = configureStore({
  reducer: {
    user: userSlice,
    dashboard: dashboardSlice,
    login: loginSlice,
    userMgmt: userMgmtSlice,
    orgMgmt: OrgMgmtSlice,
    workMgmt: workMgmtSlice,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
