import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import moment from "moment";
import {
  Grid,
  Card,
  CardContent,
  Button,
  DialogTitle,
  Alert,
  AlertTitle,
  Dialog,
  DialogContent,
  Snackbar,
} from "@mui/material";
import { Box } from "@mui/system";
import { ToastContainer, toast } from "react-toastify";

import { deleteOrgDetails, getOrgMgmtDetails } from "../redusers/orgMgmtThunk";

const DeleteOrg = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const org_id =
    props && props.selectedOrg
      ? props.selectedOrg.hospital_id
      : props.selectedOrg.hospital_id;

  const closeDialog = (e) => {
    e.preventDefault();
    props.onClose();
  };

  const deleteSelRecord = (e) => {
    e.preventDefault();
    dispatch(deleteOrgDetails(org_id))
      .unwrap()
      .then((resdata) => {
        navigate("/orgManagement");

        dispatch(getOrgMgmtDetails());
        toast.success("Organization Deleted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        //dispatch(setsuccessMessage(resdata.message));
      });
    props.onClose();
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        fullWidth="70%"
        maxWidth={props.maxWidth}
        open={props.open}
        onClose={props.onClose}
      >
        <DialogTitle
          style={{
            borderBottom: "0.8px solid #f0f1fc",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Link
              to="/"
              className="FIle-left"
              onClick={closeDialog}
              style={{ marginRight: "20px" }}
            >
              <img
                src={Back}
                className="file-img-left"
                alt=""
                style={{ width: "12px" }}
              />
            </Link>
            <span>Delete Conformation</span>
          </div>
          <div>
            <Link to="/" className="FIle-right-extreme" onClick={closeDialog}>
              <img src={Close} alt="" />
            </Link>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box>
            <form id="bmrn-form">
              <Card style={{ marginTop: 10 }} variant="none">
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Alert severity="error">
                        <AlertTitle>
                          Are you sure you want to delete selected Organization
                        </AlertTitle>
                      </Alert>
                    </Grid>
                    <div className="formSection" style={{ marginTop: "40px" }}>
                      <Grid container>
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            className="back"
                            onClick={closeDialog}
                          >
                            Back
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            className="purple-add"
                            type="submit"
                            onClick={(e) => deleteSelRecord(e, org_id)}
                          >
                            <span>Delete</span>
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteOrg;
