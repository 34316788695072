import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { ToastContainer, toast } from "react-toastify";
import {
  getPaymentPlans,
  createPlan,
  updatePlan,
  activatePlan,
} from "../../Dashboard/reducers/DashboardThunk";
import { Link } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import DeletePlan from "./DeletePlan";
const ALPHA_NUMERIC = /^[a-zA-Z0-9- ]+$/;

import { DataGrid } from "@mui/x-data-grid";
const Plans = (props) => {
  const dispatch = useDispatch();
  const [paymentPlans, setPaymentPlans] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [addPlanData, setPlanData] = useState();
  const [SelectedPlan, setSelectedPlan] = useState([]);
  const [deleteSelectedPlan, setDelete] = React.useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [showNameErrorMessage, setShowNameErrorMessage] = useState();
  const [showPriceErrorMessage, setShowPriceErrorMessage] = useState();
  const [showDurationErrorMessage, setShowDurationErrorMessage] = useState();
  const [isValid, setValid] = React.useState(true);
        useEffect(() => {
            verifyErrorFields();
          }, [errorFields]);
        
          const verifyErrorFields = async () => {
            let enableSubmitButton = true;
            const keys = Object.keys(errorFields);
            keys.map((key) => {
              errorFields[key] !== false ? (enableSubmitButton = false) : null;
            });
            setValid(enableSubmitButton);
          };


  
  const verifyPrice = (event) => {
    if (event.target.value >= 0 && event.target.value <= 100000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { price: false })
      );
      setShowPriceErrorMessage("");
    } else {
      setShowPriceErrorMessage(
        "Price range should be between 0 - 100000"
      );
      setErrorFields(
        Object.assign({}, errorFields, { price: true })
      );
    }
  };
  
  const verifyDuration = (event) => {
    if (event.target.value >= 0 && event.target.value <= 1000) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { duration_in_days: false })
      );
      setShowDurationErrorMessage("");
    } else {
      setShowDurationErrorMessage(
        "Duration should be between 0 - 1000 Days"
      );
      setErrorFields(
        Object.assign({}, errorFields, { duration_in_days: true })
      );
    }
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;

    const fieldvalue = event.target.value;
    const newFormData = { ...addPlanData };
    newFormData[fieldname] = fieldvalue;
    setPlanData(newFormData);
  };

  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    const newFormData = { ...addPlanData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    if (!addPlanData?.plan_id) {
      const req = newFormData;
      dispatch(createPlan(req))
        .unwrap()
        .then((resdata) => {
          if (resdata.status == "200") {
            dispatch(getPaymentPlans())
              .unwrap()
              .then((resdata) => {
                setPaymentPlans(resdata?.response.plans);
              });
            toast.success("Plan Created Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setsuccessMessage(resdata.message));
            setOpen(false);
          } else {
            toast.error(resdata.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setErrorMessage(resdata.message));
          }
        });
    } else if (addPlanData?.plan_id) {
      const req = newFormData;
      dispatch(updatePlan(req))
        .unwrap()
        .then((resdata) => {
          if (resdata.status == "200") {
            dispatch(getPaymentPlans())
              .unwrap()
              .then((resdata) => {
                setPaymentPlans(resdata?.response.plans);
              });
            toast.success("Plan Updated Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setsuccessMessage(resdata.message));
            setOpen(false);
          } else {
            toast.error(resdata.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setErrorMessage(resdata.message));
          }
        });
    }
  };

  const activateSubscriptionPlan = (params) => {
    dispatch(activatePlan(params.row.plan_id))
      .unwrap()
      .then(() => {
        toast.success("Plan Activated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        dispatch(getPaymentPlans())
          .unwrap()
          .then((resdata) => {
            setPaymentPlans(resdata?.response.plans);
          });
      });
  };

  useEffect(() => {
    dispatch(getPaymentPlans())
      .unwrap()
      .then((resdata) => {
        setPaymentPlans(resdata?.response.plans);
      });
  }, []);

  const handleOpen = () => {
    setOpen(true);
    setPlanData();
  };
  const handleClose = () => {
    setOpen(false);
    setPlanData();
  };
  const handleClickOpen = (params) => {
    const data = paymentPlans.filter((d) => d.plan_id === params.row.plan_id);
    setPlanData(data[0]);
    setOpen(true);
  };

  const deletePlan = (params) => {
    setSelectedPlan(params.row);
    setDelete(true);
    dispatch(getPaymentPlans())
      .unwrap()
      .then((resdata) => {
        setPaymentPlans(resdata?.response.plans);
      });
  };
  const closeDialog = () => {
    setOpen(false);
    setDelete(false);
    dispatch(getPaymentPlans())
      .unwrap()
      .then((resdata) => {
        setPaymentPlans(resdata?.response.plans);
      });
  };

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const columns = [
    {
      field: "name",
      headerName: <span className="gridheader_font">Coupon Name</span>,
      width: 500,
      flex: 1,
      renderCell: (params) => <span className="dadata">{params.row.name}</span>,
    },
    {
      field: "price",
      flex: 1,
      headerName: <span className="gridheader_font">Price</span>,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">{params.row.price}</span>
      ),
    },
    {
      field: "duration_in_days",
      flex: 1,
      headerName: <span className="gridheader_font">Duration Dates</span>,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">{params.row.duration_in_days}</span>
      ),
    },

    {
      field: "fullName",
      headerName: "Action Buttons",
      width: 80,
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: 10,
              background: "#6572E4",
              //background: "#F98F60",
              borderRadius: 4,
              fontSize: 10,
              boxShadow: "none",
              textTransform: "none",
            }}
            className="view_Reports"
            onClick={() => handleClickOpen(params)}
          >
            Edit
          </Button>
          {params.row.active_flag ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#FF6347",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => deletePlan(params)}
            >
              Delete
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                //background: "#F98F60",

                background: "#008000",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => activateSubscriptionPlan(params)}
            >
              Activate
            </Button>
          )}
        </strong>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="dataoutlet">
        <div className="formSection">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Card variant="none">
                <CardContent>
                  <form id="mother-medical-form">
                    <Grid
                      container
                      style={{
                        borderBottom: "0.8px solid rgba(101, 114, 228, 0.2)",
                      }}
                    >
                      <Grid item xs={12} sm={10}>
                        <p className="dashboard-title">
                          List Of Subscription Plans
                        </p>
                      </Grid>

                      <Grid item xs={12} sm={2} className="header">
                        <Button
                          variant="outlined"
                          className="branch_Add"
                          onClick={handleOpen}
                          style={{ float: "right" }}
                        >
                          + Add Plan
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: "10px" }}>
                      <Grid item xs={12} sm={12} className="header">
                        <div style={{ height: 550, width: "100%" }}>
                          <DataGrid
                            rows={paymentPlans}
                            columns={columns}
                            pageSize={8}
                            rowsPerPageOptions={[10]}
                            showCellRightBorder={true}
                            showColumnRightBorder={true}
                            getRowId={(row) => row.plan_id}
                            cellClassName="customCell"
                            components={{
                              NoRowsOverlay: () => (
                                <Stack
                                  height="100%"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  No Plans Found
                                </Stack>
                              ),
                              NoResultsOverlay: () => (
                                <Stack
                                  height="100%"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  No Plans Found
                                </Stack>
                              ),
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "0.8px solid",
              borderColor: "rgba(101, 114, 228, 0.4)",
            }}
          >
            <span>
              <Link
                className="FIle-left"
                onClick={handleClose}
                style={{ marginRight: "20px" }}
              >
                <img
                  src={Back}
                  className="file-img-left"
                  alt=""
                  style={{ width: "12px" }}
                />
              </Link>
              {!addPlanData?.plan_id ? "Add" : "Update"} Plan
            </span>
            <Link className="FIle-right-extreme" onClick={handleClose}>
              <img src={Close} style={{ marginRight: "20px" }} alt="" />
            </Link>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="City"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Plan Name
                  </label>
                  <TextField
                    id="name"
                    name="name"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    onChange={handleAddFormChange}
                    value={addPlanData?.name ? addPlanData?.name : ""}
                    required
                    onKeyDown={(event) => {
                      if (
                        !ALPHA_NUMERIC.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowNameErrorMessage(
                          "Only Numerics and Alphabets are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            name: true,
                          })
                        );
                      }
                      else{
                        setShowNameErrorMessage();
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            name: false,
                          })
                        );
                      }
                    }}
                    error={
                      errorFields.name &&
                      showNameErrorMessage
                    }
                    helperText={
                      errorFields.name &&
                      showNameErrorMessage
                        ? showNameErrorMessage
                        : ""
                    }
                    //helperText={showErrorMessage}
                    errorText={showNameErrorMessage}
                    InputProps={{
                      inputProps: {
                        maxLength: 20,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="City"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Price
                  </label>
                  <TextField
                    id="price"
                    name="price"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    //onChange={handleAddFormChange}
                    value={addPlanData?.price ? addPlanData?.price : ""}
                    required
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyPrice(e);
                    }}
                    error={
                      errorFields.price &&
                      showPriceErrorMessage
                    }
                    errorText={showPriceErrorMessage}
                    helperText={
                      errorFields.price &&
                      showPriceErrorMessage
                        ? showPriceErrorMessage
                        : ""
                    }
                    InputProps={{
                      inputProps: {
                        maxLength: 6,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="Duration in Days"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Duration in Days
                  </label>
                  <TextField
                    id="duration_in_days"
                    name="duration_in_days"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    //onChange={handleAddFormChange}
                    value={
                      addPlanData?.duration_in_days
                        ? addPlanData?.duration_in_days
                        : ""
                    }
                    required
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyDuration(e);
                    }}
                    error={
                      errorFields.duration_in_days &&
                      showDurationErrorMessage
                    }
                    errorText={showDurationErrorMessage}
                    helperText={
                      errorFields.duration_in_days &&
                      showDurationErrorMessage
                        ? showDurationErrorMessage
                        : ""
                    }
                    InputProps={{
                      inputProps: {
                        maxLength: 4,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="Description"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Description
                  </label>
                  <TextField
                    id="description"
                    name="description"
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    multiline
                    rows={2}
                    onChange={handleAddFormChange}
                    value={
                      addPlanData?.description ? addPlanData?.description : ""
                    }
                    InputProps={{
                      inputProps: {
                        maxLength: 500,
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <div className="formSection">
                <Grid
                  container
                  xs={12}
                  sm={12}
                  style={{ justifyContent: "center" }}
                >
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant="contained"
                      className="back"
                      onClick={handleClose}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {!addPlanData?.plan_id ? (
                      <div>
                            {isValid ? (
                        <Button
                          variant="contained"
                          className="purple-add"
                          type="submit"
                          onClick={handleClick({
                            vertical: "top",
                            horizontal: "center",
                          })}
                        >
                          <span>Add Plan</span>
                        </Button>
                            ):(
                              <Button
                          variant="contained"
                          className="back"
                        >
                          <span>Add Plan</span>
                        </Button>
                            )}
                      </div>
                    ) : (
                      <div>
                            {isValid ? (
                        <Button
                          variant="contained"
                          className="purple-add1"
                          type="submit"
                          onClick={handleClick({
                            vertical: "top",
                            horizontal: "center",
                          })}
                        >
                          <span>Update Plan</span>
                        </Button>
                            ):(
                              
                        <Button
                        variant="contained"
                        className="back"
                      >
                        <span>Update Plan</span>
                      </Button>
                            )}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            </form>
          </DialogContent>
        </Dialog>
        {deleteSelectedPlan && (
          <DeletePlan
            SelectedPlan={SelectedPlan}
            open={deleteSelectedPlan}
            onClose={closeDialog}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Plans;
