import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../api/apiCommonService";

export const getActivityDetails = createAsyncThunk(
  "getActivityDetails",
  async (req) => {
    const res = await DataService.getActivityDetails(req);
    return res.data;
  }
);

export const getDashboardStatistics = createAsyncThunk(
  "getDashboardStatistics",
  async (req) => {
    const res = await DataService.getDashboardStatistics(req);
    return res.data;
  }
);

export const getActivityTypes = createAsyncThunk(
  "getActivityTypes",
  async (req) => {
    const res = await DataService.getActivityTypes(req);
    return res.data;
  }
);

export const getOrganizations = createAsyncThunk(
  "getOrganizations",
  async (req) => {
    const res = await DataService.getOrganizations(req);
    return res.data;
  }
);

export const getAllUsers = createAsyncThunk("getAllUsers", async (req) => {
  const res = await DataService.getAllUsers(req);
  return res.data;
});

export const getAllUsersByOrg = createAsyncThunk("getAllUsersByOrg", async (req) => {
  const res = await DataService.getAllUsersByOrg(req);
  return res.data;
});


export const getCoupons = createAsyncThunk("getCoupons", async (req) => {
  const res = await DataService.getCoupons(req);
  return res.data;
});

export const getReports = createAsyncThunk("getReports", async (req) => {
  const res = await DataService.getReports(req);
  return res.data;
});

export const createCoupon = createAsyncThunk("createCoupon", async (req) => {
  const res = await DataService.createCoupon(req);
  return res.data;
});

export const updateCoupon = createAsyncThunk("updateCoupon", async (req) => {
  const res = await DataService.updateCoupon(req);
  return res.data;
});

export const deletePlan = createAsyncThunk("deletePlan", async (plan_id) => {
  const res = await DataService.deletePlan(plan_id);
  return res.data;
});

export const activateCoupon = createAsyncThunk(
  "activateCoupon",
  async (coupon_id) => {
    const res = await DataService.activateCoupon(coupon_id);
    return res.data;
  }
);

export const createPlan = createAsyncThunk("createPlan", async (req) => {
  const res = await DataService.createPlan(req);
  return res.data;
});

export const updatePlan = createAsyncThunk("updatePlan", async (req) => {
  const res = await DataService.updatePlan(req);
  return res.data;
});

export const getPaymentPlans = createAsyncThunk(
  "getPaymentPlans",
  async (req) => {
    const res = await DataService.getPaymentPlans(req);
    return res.data;
  }
);

export const deleteCoupon = createAsyncThunk(
  "deleteCoupon",
  async (coupon_id) => {
    const res = await DataService.deleteCoupon(coupon_id);
    return res.data;
  }
);

export const activatePlan = createAsyncThunk(
  "activatePlan",
  async (plan_id) => {
    const res = await DataService.activatePlan(plan_id);
    return res.data;
  }
);
